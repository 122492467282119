<template>
    <div class="home">
      <el-menu :default-active="formInline.status + ''" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="0">全部</el-menu-item>
        <el-menu-item index="1">待处理</el-menu-item>
        <el-menu-item index="2">处理中</el-menu-item>
        <el-menu-item index="3">已处理</el-menu-item>
      </el-menu>
      <div class="pt20"></div>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="创建时间">
                <el-date-picker
                    v-model="timevalue"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :picker-options="pickerOptions">
                </el-date-picker>
            </el-form-item>
        <el-form-item style="font-weight: bold;" label="关键字">
            <el-input v-model="formInline.title" style="width:400px" placeholder="关键字"></el-input>
            <el-button  type="primary"  style="margin-left:20px" @click="onSubmit('CHA')">查询</el-button>
        </el-form-item>
      </el-form> 
      <el-table
        v-loading="loading"
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
         >
       <el-table-column label="操作" 
            width="120" >
            <template  slot-scope="scope">
                <el-button @click='editbug_feed(scope.row.id)' size="mini" type="info" plain>编辑</el-button>
            </template>
        </el-table-column>
       <el-table-column
          width="250"
          prop="created_at"
          label="创建时间">
        </el-table-column>
        <el-table-column
          prop="no"
          label="反馈单号">
        </el-table-column>
        <el-table-column
          width="150"
          prop="name"
          label="姓名">
        </el-table-column>
        <el-table-column
          width="150"
          prop="question_label.name"
          label="问题类型">
        </el-table-column>
        <el-table-column
          width="150"
          prop="os_version"
          label="系统版本">
        </el-table-column>
        <el-table-column
          width="150"
          prop="app_version"
          label="app版本">
        </el-table-column>
        <el-table-column
          prop="platform" 
          width="120"
          label="来源">
        </el-table-column>
        <el-table-column
          prop="status"
          width="150"
          label="处理状态">
          <template  slot-scope="scope">
                  {{scope.row.status==1?'待处理':''}}
                  {{scope.row.status==2?'处理中':''}}
                  {{scope.row.status==3?'已处理':''}}
          </template>
        </el-table-column>
      </el-table>
      <div class="pt20"></div>
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page.sync="current_page"
          :page-size="per_page"
          layout="total,prev, pager, next, jumper"
          :total="total">
        </el-pagination>
        
        <el-dialog
        title="编辑"
        :visible.sync="e_bugfeed"
        :fullscreen="false"
        :modal="false"
        width="90%"
        hight="90%"
        center>
        <el-menu :default-active="e_type + ''" class="el-menu-demo" mode="horizontal" @select="e_Select">
            <el-menu-item index="0">订单详情</el-menu-item>
            <el-menu-item index="1">处理记录</el-menu-item>
        </el-menu>
        <div v-if="e_type == 0">
            <div class="pt20"></div>
            <el-descriptions class="margin-top" title="基本信息" :column="3" :size="size" border> 
                <el-descriptions-item>
                    <template slot="label">
                        系统来源
                    </template>
                    {{e_tData.platform}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                        创建时间
                    </template>
                    {{e_tData.created_at}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                        系统版本
                    </template>
                    {{e_tData.os_version}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                        app版本
                    </template>
                    {{e_tData.app_version}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                        反馈单号
                    </template>
                    {{e_tData.no}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                        处理状态
                    </template>
                    {{e_tData.status==1?'待处理':''}}
                    {{e_tData.status==2?'处理中':''}}
                    {{e_tData.status==3?'已处理':''}}
                    </el-descriptions-item>
            </el-descriptions>
            <div class="pt20"></div>
            <el-descriptions class="margin-top" title="订单信息" :column="2" :size="size" border> 
                <el-descriptions-item>
                    <template slot="label">
                        姓名
                    </template>
                    {{e_tData.name}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                        注册手机
                    </template>
                    {{e_tData.phone}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                        联系邮箱
                    </template>
                    {{e_tData.email}}
                    </el-descriptions-item>
                    <el-descriptions-item>
                    <template slot="label">
                        问题类型
                    </template>
                    {{e_tData.question_label ? e_tData.question_label.name : ''}}
                    </el-descriptions-item>
            </el-descriptions>
            <div class="pt20"></div>
            <h3 style="color: #303133;">问题截图</h3>
            <div style="display: flex;">
              <div style="margin-right: 10px;" v-for="(img,index) in e_tData.imgs" :key="index">
                      <el-image v-if="img"
                      style="width: 200px; height: 200px"
                      :src="img"
                      fit="contain"></el-image>
              </div>
            </div>
            <div class="pt20"></div>
            <h3 style="color: #303133;">用户描述</h3>
            <div>{{e_tData.content}}</div>
            <div class="pt20"></div>
            <h3 style="color: #303133;">处理信息</h3>
            <div>
                <el-input v-model.trim="feed_backdata.del_content" placeholder="请输入" type="textarea" maxlength="200" show-word-limit clearable required></el-input>
            </div>
            <div class="pt20"></div>
            <h3 style="color: #303133;">处理状态</h3>
            <el-select  v-model="feed_backdata.status" class="select1" placeholder="请选择">
                        <el-option label="待处理" :value="1"></el-option>
                        <el-option label="处理中" :value="2"></el-option>
                        <el-option label="已处理" :value="3"></el-option>
            </el-select>
            <div class="pt20"></div>
            <div style="text-align: center;" >
                <el-button type="primary" @click='submit'>提交</el-button> 
            </div>
        </div>
        <div v-if="e_type == 1">
          <el-table
        v-loading="loading"
        :data="e_logData"
        style="width: 100%"
        :header-cell-style="{background:'#F5F7FA'}"
         >
       <el-table-column
          prop="created_at"
          label="处理时间">
        </el-table-column>
        <el-table-column
          prop="del_content"
          label="处理内容">
        </el-table-column>
        <el-table-column
          prop="user.name"
          label="处理人员">
        </el-table-column>
      </el-table>
        </div>
      </el-dialog>
    </div>
</template>

<script>
// @ is an alias to /src   
import axios from 'axios'
import config from '../../lib/config'
import formatDate from '../../lib/dateFormat'

export default {
  name: 'bugfeed',
  data(){
    return {
      loading:true,
      e_bugfeed:false,
      e_type:0,
      total:0,
      per_page: 0,
      current_page:1,
      size: '',
      e_logData:[],
      tableData:[],
      e_tData:{},
      timevalue:'',
      e_id:0,
      feed_backdata:{
        status:1,
        del_content:'',
        id:0,
      },
      formInline: {
         status:0,//状态 0全部 1待处理 2处理中 3已处理
         title:'',//检索内容
         s_date:'',//创建开始时间 示例 2024-01-01
         e_date:'',//创建结束时间 示例 2024-01-02
         limit:'',//分页数据
      },
     //选中的记录数量
     initdata:{
         status:0,//状态 0全部 1待处理 2处理中 3已处理
         title:'',//检索内容
         s_date:'',//创建开始时间 示例 2024-01-01
         e_date:'',//创建结束时间 示例 2024-01-02
         limit:'',//分页数据
     },
     pickerOptions: {
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {  
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
    }
  },
  methods: {
      e_Select(key){
        let _this = this;
        _this.e_type = key;
        if(key == 0){
            _this.editbug_feed(_this.e_id);
        }
        if(key == 1){
            _this.getbugfeedbacklog();
        }
      },
      getbugfeedbacklog(){
        let _this = this;
        let data = {
            id:_this.e_id,
        }
        axios.get(config.bug_feed_back_log,{params:data})
        .then(function (response) {
            if(response.data.code == 200){
              _this.e_logData = response.data.data;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      editbug_feed(id){
        let _this = this;
        _this.e_id = id;
        let data = {
            id:id,
        }
        _this.e_type = 0;
        _this.e_bugfeed = true;
        _this.feed_backdata = {
            status:1,
            del_content:'',
            id:0,
        };
        axios.get(config.bug_feed_back_info,{params:data})
        .then(function (response) {
            if(response.data.code == 200){
              _this.e_tData = response.data.data;
              _this.e_tData.imgs = _this.e_tData.images.split(',');
              _this.feed_backdata.status = _this.e_tData.status;
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      submit(){
        let _this = this;
        _this.feed_backdata.id =  _this.e_id;
      axios.post(config.bug_feed_back,_this.feed_backdata)
        .then(function (response) {
            if(response.data.code == 200){
              _this.$message({
                type: 'success',
                message: '成功!'
              });
              _this.e_bugfeed = false;
              _this.onSubmit();
            }else{
              _this.$message.error(response.data.message);
            }
          })
        .catch(function (error) {
          console.log(error);
        });
      },
      onSubmit(CHA){
        let _this = this;
        _this.loading = true;
        if(CHA == 'CHA'){
          this.formInline.page = 1;
        }
          _this.formInline.s_date = '';
          _this.formInline.e_date = '';
          if(_this.timevalue){
            _this.formInline.s_date = formatDate(_this.timevalue[0]);
            _this.formInline.e_date = formatDate(_this.timevalue[1]);
          }
        axios.get(config.bug_feed_back,{params:_this.formInline})
        .then(function (response) {
              if(response.data.code == 200){
                _this.tableData = response.data.data.data
                _this.setpage(response.data.data)
                }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
      },
      handleSelect(key) {
        this.formInline.status = key;
        this.onSubmit('CHA');
      },
      setpage(page){
        this.total = page.total;
        this.per_page = page.per_page;
        this.current_page = page.current_page;
      },
      handleCurrentChange(val) {
        this.formInline.page = val;
        this.onSubmit();
        document.getElementsByClassName('el-main')[0].scrollTop = 0;
      },
    init(){
        let _this = this;
        axios.get(config.bug_feed_back,{params:this.initdata})
        .then(function (response) { 
            if(response.data.code == 200){
              _this.tableData = response.data.data.data
              _this.setpage(response.data.data)
            }
              _this.loading = false
          })
        .catch(function (error) {
          _this.$message.error(error);
        });
    }
  },
  created(){
      this.init();
  }
}
</script>
<style scoped>
.left{
    width: 70px;
    text-align: left;
}
</style>